$textGeneral: var(--text-general);

@mixin globalPTextStyles {
  font-size: 0.8rem;
  text-align: left;
  color: $textGeneral;
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

@mixin cursorPointer {
  cursor: pointer !important;
}

@mixin cursorNone {
  cursor: none !important;
}
