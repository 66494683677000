$base-ZIndex: var(--navbar-ZIndex);
$logo-width: var(--navbar-logo-width);
$navbar-padding-vertical: var(--navbar-padding-vertical);
$navbar-hamburgerBgColor: var(--navbar-hamburgerBgColor);
$navbar-hamburgerLinkColor: var(--navbar-hamburgerLinkColor);
$navbar-hamburgerLinkHoverColor: var(--navbar-hamburgerLinkHoverColor);
$navbar-hamburgerAnimationDuration: var(--navbar-hamburgerAnimationDuration);

#hamburgerNavDefault {
  position: relative;
  display: none !important;
  // overflow-x: hidden;

  #hamburgerMenuToggle {
    display: block;
    position: relative;
    z-index: calc(#{$base-ZIndex} + 1);

    -webkit-user-select: none;
    user-select: none;

    a {
      text-decoration: none;
      color: $navbar-hamburgerLinkColor;

      transition: color 0.3s ease;

      &:hover {
        color: $navbar-hamburgerLinkHoverColor;
      }
    }

    input {
      display: block;
      width: 2rem;
      height: 22px;
      position: absolute;
      top: 0;
      left: 0;

      opacity: 0; /* hide this */
      z-index: calc(#{$base-ZIndex} + 4); /* and place it over the hamburger */

      -webkit-touch-callout: none;

      &:checked ~ span {
        width: 2rem;
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #762525;

        &:nth-last-of-type(2) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        &:nth-last-of-type(1) {
          transform: rotate(-45deg) translate(0, -1px);
        }
      }

      &:checked ~ #hamburgerMenuWrapper #hamburgerMenu {
        transform: none;
      }
    }

    span {
      display: block;
      width: 2rem;
      height: 0.25rem;
      margin-bottom: 5px;
      position: relative;

      background: #cdcdcd;
      border-radius: 3px;

      z-index: calc(#{$base-ZIndex} + 3);

      transform-origin: 4px 0px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }

      &:nth-last-of-type(1) {
        transform-origin: 0% 100%;
        margin-bottom: 0;
      }
    }
  }

  #hamburgerMenuWrapper {
    position: absolute;
    top: calc(
      -1 * (((#{$logo-width} - 22px) / 2) + #{$navbar-padding-vertical})
    );
    // top: 0;
    left: -0.5rem;
    // width: 10rem;
    // height: 25rem;
    overflow-x: hidden;
    z-index: $base-ZIndex;

    #hamburgerMenu {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 0.5rem;
      opacity: 0.9;
      gap: 0.5rem;
      z-index: calc(#{$base-ZIndex} + 2);
      top: 0;
      left: 0;
      padding: 1rem;
      margin-top: calc(
        ((#{$logo-width} - 22px) / 2) + #{$navbar-padding-vertical} + 22px
      );

      background: $navbar-hamburgerBgColor;
      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */

      transform-origin: 0% 0%;
      transform: translate(-101%, 0);

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

      li {
        padding: 0.6rem 0;
        font-size: 1.5rem;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    display: flex !important;
  }
}

#hamburgerNavToLeft {
  @extend #hamburgerNavDefault;

  #hamburgerMenuToggle {
    span {
      transform-origin: 100% 0; // Sağ üst köşe

      &:first-child {
        transform-origin: 100% 0; // Sağ üst köşe
      }

      &:nth-last-of-type(1) {
        transform-origin: 100% 100%; // Sağ alt köşe
      }
    }

    input {
      &:checked ~ span {
        &:nth-last-of-type(3) {
          transform: rotate(-45deg) translate(2px, -1px);
        }

        &:nth-last-of-type(1) {
          transform: rotate(45deg) translate(0, 1px);
        }
      }
    }
  }

  #hamburgerMenuWrapper {
    left: auto;
    right: 0;

    #hamburgerMenu {
      left: auto;
      right: 0;
      align-items: flex-end;
      transform: translate(101%, 0%);
    }
  }
}
