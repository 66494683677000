$base-ZIndex: var(--navbar-ZIndex);
$header-BadgeShadowColor: var(--header-BadgeShadow);
$header-TextColor: var(--text-header);
$btnGeneralBgColor: var(--btn-generalBgColor);
$btnTextColor: var(--btn-textColor);

#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;

  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }

  &-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    margin: 0 2rem;

    @media screen and (max-width: 1200px) {
      width: 100%;
      margin-right: 0rem;
    }

    .app__header-badge {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      .app__header-buttons {
        gap: 0.5rem;
        margin-top: 1rem;

        padding: 1rem 2rem;
        background: var(--white-color);
        border-radius: 15px;

        box-shadow: 0px 0px 20px $header-BadgeShadowColor;

        // GRID EKLENTİSİ
        display: grid; // Grid yapısı oluşturulur
        grid-template-columns: repeat(2, 1fr); // 2 column
        gap: 1rem; // Grid item'lar arasında boşluk

        &-item {
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          background-color: $btnGeneralBgColor;
          color: $btnTextColor !important;
          font-weight: 800;

          cursor: pointer;
          transition: all 0.3s ease;

          text-transform: uppercase;
          text-align: right;

          &-text {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            a {
              text-decoration: none;
              color: inherit;
              font-weight: inherit;
            }
          }

          &:hover {
            // background-color: var(--secondary-color);
            // color: #fff;
            filter: drop-shadow(0 0 3px red) brightness(80%);
          }

          @media screen and (min-width: 2000px) {
            border-radius: 0.85rem;
          }
        }
      }

      .badge-cmp,
      .tag-cmp {
        padding: 1rem 2rem;
        background: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0px 0px 20px $header-BadgeShadowColor;
      }

      .tag-cmp {
        flex-direction: column;
        margin-top: 2rem;

        p {
          width: 100%;
          text-transform: uppercase;
          text-align: right;
        }
      }
      // span {
      //   font-size: 2.5rem;

      //   @media screen and (min-width: 2000px) {
      //     font-size: 5rem;
      //   }
      // }

      img {
        width: 2.5rem;
        height: 2.5rem;

        @media screen and (min-width: 2000px) {
          width: 5rem;
          height: 5rem;
        }
      }

      @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  &-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;

      background: var(--white-color);
      box-shadow: 0px 0px 20px $header-BadgeShadowColor;

      img {
        width: 60%;
        height: 60%;
      }
    }

    // circles with different height & width

    div:nth-child(1) {
      width: 100px;
      height: 100px;
    }

    div:nth-child(2) {
      margin: 1.75rem;
      width: 150px;
      height: 150px;
    }

    div:nth-child(3) {
      width: 70px;
      height: 70px;
    }

    @media screen and (min-width: 2000px) {
      div:nth-child(2) {
        width: 400px;
        height: 400px;
      }

      div:nth-child(3) {
        width: 170px;
        height: 170px;
      }

      div:nth-child(1) {
        width: 200px;
        height: 200px;
      }
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0;

      div {
        margin: 1rem;
      }
    }
  }

  &-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    img {
      width: 100%;
      object-fit: contain;
      z-index: calc(#{$base-ZIndex} - 1);
    }

    .overlay_circle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: calc(#{$base-ZIndex} - 2);
      width: 100%;
      height: 90%;

      @media screen and (min-width: 1201px) and (max-width: 1400px) {
        top: 35%;
      }

      @media screen and (min-width: 1401px) and (max-width: 1450px) {
        top: 30%;
      }

      @media screen and (min-width: 1451px) and (max-width: 1600px) {
        top: 25%;
      }
    }

    @media screen and (max-width: 1200px) {
      margin: 2rem 0;
    }
  }
}

.header--text {
  color: $header-TextColor !important;
}
